import React, { useEffect, useState, useContext } from 'react';
import { useServers } from '../hooks/useServers';
import { Plus, Edit, Trash, Copy, Check, ChevronDown, ChevronUp, Code, Key, CheckCircle, Shield } from 'lucide-react';
import { useAuth } from '../context/AuthContext'; // Assume this exists

const ServerSkeleton = () => (
  <div className="bg-gray-700 p-3 rounded-lg animate-pulse">
    <div className="flex justify-between items-center mb-1">
      <div className="h-5 bg-gray-600 rounded w-1/4"></div>
      <div className="flex items-center space-x-1">
        <div className="w-4 h-4 bg-gray-600 rounded"></div>
        <div className="w-4 h-4 bg-gray-600 rounded"></div>
      </div>
    </div>
    <div className="h-4 bg-gray-600 rounded w-3/4 mt-2"></div>
  </div>
);

const ServerCard = ({ server, onEdit, onDelete, onCopy, editingServer, setEditingServer, deletingServerId, copiedKey }) => (
  <div className="bg-gray-700 p-3 rounded-lg mb-4">
    <div className="flex justify-between items-center mb-1">
      <span className="font-bold">{server.name}</span>
      <div className="flex items-center space-x-1">
        <button
          className="text-blue-400 p-1"
          title="Edit"
          onClick={() => onEdit(server)}
        >
          <Edit className="w-4 h-4" />
        </button>
        {deletingServerId === server.id ? (
          <>
            <button
              className="text-red-400 text-sm"
              onClick={() => onDelete(server.id)}
            >
              Confirm
            </button>
            <button
              className="text-gray-400 text-sm"
              onClick={() => onDelete(null)}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            className="text-red-400 p-1"
            title="Delete"
            onClick={() => onDelete(server.id)}
          >
            <Trash className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
    {editingServer && editingServer.id === server.id ? (
      <div className="mt-1">
        <input
          className="w-full px-2 py-1 mb-1 bg-gray-600 border border-gray-500 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={editingServer.name}
          onChange={(e) => setEditingServer({ ...editingServer, name: e.target.value })}
        />
        <input
          className="w-full px-2 py-1 mb-1 bg-gray-600 border border-gray-500 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={editingServer.description}
          onChange={(e) => setEditingServer({ ...editingServer, description: e.target.value })}
        />
        <div className="flex justify-end space-x-2">
          <button
            className="text-green-400 text-sm"
            onClick={() => onEdit(server)}
          >
            Save
          </button>
          <button
            className="text-gray-400 text-sm"
            onClick={() => setEditingServer(null)}
          >
            Cancel
          </button>
        </div>
      </div>
    ) : (
      <div className="flex items-center justify-between text-sm">
        <p className="text-gray-400">{server.description}</p>
        <div className="flex items-center ml-2 relative">
          <label className="text-gray-400 text-xs mr-2">API Key:</label>
          <span className="text-gray-300 font-mono bg-gray-600 px-2 py-1 rounded text-xs">{server.apiKey}</span>
          <button
            className="text-green-400 ml-1 relative"
            title={copiedKey === server.id ? "Copied!" : "Copy API Key"}
            onClick={() => onCopy(server)}
          >
            {copiedKey === server.id ? (
              <Check className="w-4 h-4" />
            ) : (
              <Copy className="w-4 h-4" />
            )}
            {copiedKey === server.id && (
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-900 text-xs py-1 px-2 rounded shadow-lg">
                Copied!
              </span>
            )}
          </button>
        </div>
      </div>
    )}
  </div>
);

const Servers = ({onClose }) => {
    const { user, latestReleaseTag } = useAuth();
    const { servers, loading, error, loadServers, addNewServer, deleteServer, editServer } = useServers(user.id);
    const [formData, setFormData] = useState({ name: '', description: '' });
    const [editingServer, setEditingServer] = useState(null);
    const [copiedKey, setCopiedKey] = useState(null);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [deletingServerId, setDeletingServerId] = useState(null);
    const [isCreatingServer, setIsCreatingServer] = useState(false);
    const [isInstructionsCollapsed, setIsInstructionsCollapsed] = useState(() => {
      const storedValue = localStorage.getItem('isInstructionsCollapsed');
      return storedValue !== null ? JSON.parse(storedValue) : false;
    });

  useEffect(() => {
    loadServers();
  }, [loadServers]);

  useEffect(() => {
    localStorage.setItem('isInstructionsCollapsed', JSON.stringify(isInstructionsCollapsed));
  }, [isInstructionsCollapsed]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const canAddServer = () => {
    if (user.userPlan === 'trial' && servers.length >= 10) {
      return false;
    }
    return true;
  };

  const handleAddServer = async (e) => {
    e.preventDefault();
    setIsCreatingServer(true);
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Server name is required';
    if (!formData.description.trim()) newErrors.description = 'Server description is required';
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsCreatingServer(false);
      return;
    }

    if (!canAddServer()) {
      setErrors({ general: 'You cannot add more servers on the trial plan.' });
      setIsCreatingServer(false);
      return;
    }

    await addNewServer(formData.name, formData.description);
    setFormData({ name: '', description: '' });
    setSuccess(true);
    setTimeout(() => setSuccess(false), 2000);
    setIsCreatingServer(false);
  };

  const handleEditServer = async (server) => {
    if (editingServer && editingServer.id === server.id) {
      await editServer(server.id, editingServer.name, editingServer.description);
      setEditingServer(null);
    } else {
      setEditingServer({ ...server });
    }
  };

  const handleDeleteServer = async (serverId) => {
    if (deletingServerId === serverId) {
      await deleteServer(serverId);
      setDeletingServerId(null);
    } else {
      setDeletingServerId(serverId);
    }
  };

  const copyApiKey = (server) => {
    console.log(server);
    navigator.clipboard.writeText(server.apiKey);
    setCopiedKey(server.id);
    setTimeout(() => setCopiedKey(null), 2000);
  };

  const renderServerColumns = () => {
    const midPoint = Math.ceil(servers.length / 2);
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          {servers.slice(0, midPoint).map((server) => (
            <ServerCard
              key={server.id}
              server={server}
              onEdit={handleEditServer}
              onDelete={handleDeleteServer}
              onCopy={copyApiKey}
              editingServer={editingServer}
              setEditingServer={setEditingServer}
              deletingServerId={deletingServerId}
              copiedKey={copiedKey}
            />
          ))}
        </div>
        <div>
          {servers.slice(midPoint).map((server) => (
            <ServerCard
              key={server.id}
              server={server}
              onEdit={handleEditServer}
              onDelete={handleDeleteServer}
              onCopy={copyApiKey}
              editingServer={editingServer}
              setEditingServer={setEditingServer}
              deletingServerId={deletingServerId}
              copiedKey={copiedKey}
            />
          ))}
        </div>
      </div>
    );
  };
  
  return (
    <div className="flex text-gray-300 flex-col sm:flex-row h-[calc(100%-28px)] w-[calc(100%-4px)] mx-auto bg-gray-800 text-gray-100 rounded-lg overflow-hidden">
      <div className="p-4 flex-shrink-0 sm:w-64  flex flex-col justify-between">
        <h2 className="text-xl font-bold mb-4">Manage Servers</h2>
        {/* Add Server Form */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Add New Server</h3>
          <div className="mb-2">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className={`bg-gray-700 border ${errors.name ? 'border-red-500' : 'border-gray-600'} rounded px-2 py-1 w-full`}
              placeholder="New Server Name"
              disabled={isCreatingServer}
            />
            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
          </div>
          <div className="mb-2">
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className={`bg-gray-700 border ${errors.description ? 'border-red-500' : 'border-gray-600'} rounded px-2 py-1 w-full`}
              placeholder="New Server Description"
              disabled={isCreatingServer}
            />
            {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}
          </div>
          <button
            onClick={handleAddServer}
            className={`bg-blue-600 px-4 py-2 rounded flex items-center justify-center w-full ${!canAddServer() ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!canAddServer() || isCreatingServer}
          >
            <Plus className="w-4 h-4 mr-2" />
            {isCreatingServer ? 'Creating...' : 'Create Server'}
          </button>
          {user.userPlan === 'trial' && servers.length >= 10 && (
            <p className="text-xs text-yellow-500 mt-2">
              Upgrade to create more servers.
            </p>
          )}
        </div>
        {/* New Links */}
        <div className="mt-auto">
          <a href="https://github.com/OfflineML/Server-Status-Client" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:text-blue-300 mb-2">
            <Code className="w-4 h-4 mr-2" />
            Source Code
          </a>
          <a href="https://github.com/OfflineML/Server-Status-Client?tab=readme-ov-file#change-api-key-other-configurations-and-verify-configurations" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:text-blue-300 mb-2">
            <Key className="w-4 h-4 mr-2" />
            Change API Key
          </a>
          <a href="https://github.com/OfflineML/Server-Status-Client?tab=readme-ov-file#change-api-key-other-configurations-and-verify-configurations" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:text-blue-300 mb-2">
            <CheckCircle className="w-4 h-4 mr-2" />
            Verify Configurations
          </a>
          <a href="https://github.com/OfflineML/Server-Status-Client" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:text-blue-300">
            <Shield className="w-4 h-4 mr-2" />
            Audit the code
          </a>
        </div>
      </div>

      {/* Server List */}
      <div className="flex-1 overflow-y-auto px-4 pb-4 flex flex-col">
        <h3 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-800 py-2">Servers</h3>
        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ServerSkeleton />
            <ServerSkeleton />
          </div>
        ) : (
          renderServerColumns()
        )}
        {servers.length > 0 && (
          <div className="mt-auto pt-4 border-t border-gray-700">
            <button
              onClick={() => setIsInstructionsCollapsed(!isInstructionsCollapsed)}
              className="flex items-center justify-between w-full text-left text-md text-gray-300 mb-2"
            >
              <span>Installation Instructions</span>
              {isInstructionsCollapsed ? <ChevronDown className="w-4 h-4" /> : <ChevronUp className="w-4 h-4" />}
            </button>
            {!isInstructionsCollapsed && (
              <div className="text-xs">
                <p className="mb-1">To integrate a Linux server, run the following command with the server's API key at the end:</p>
                <code className="block bg-gray-700 p-2 rounded">
                  wget -O - https://raw.githubusercontent.com/OfflineML/Server-Status-Client/main/install_linux.sh | sudo sh -s {latestReleaseTag || 'v0.1.7'}<span className="text-red-500 select-none"> &lt;Server's API Key&gt;</span>
                </code>
                <p className="mt-2 mb-1">To integrate a Windows server, run the following command in PowerShell <span className="text-yellow-500 font-bold">Administrator mode</span>:</p>
                <code className="block bg-gray-700 p-2 rounded">
                  {`powershell -Command "& {Invoke-WebRequest -Uri 'https://raw.githubusercontent.com/OfflineML/Server-Status-Client/main/install_windows.bat' -OutFile .\\temp_script.bat; .\\temp_script.bat ${latestReleaseTag || 'v0.1.7'}; Remove-Item .\\temp_script.bat}"`}
                  <span className="text-red-500 select-none"></span>
                </code>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Servers;