import React, { useState, useRef, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, AreaChart, Area} from 'recharts';
import { HardDrive, Cpu, ArrowDownUp, MemoryStick, TimerReset} from 'lucide-react';
import '../styles/ServerOverview.css';
import { setBenchmark, fetchChartData } from '../services/api';
import { createPortal } from 'react-dom';


const ServerOverview = ({ data, viewId, handlePause, handleExteralButtonClick}) => {
  const {
    id: serverId,
    is_no_data,
    name,
    can_operate,
    avg_cpu,
    max_cpu,
    avg_ram,
    max_ram,
    avg_hdd,
    max_hdd,
    hdd,
    cpu_trend,
    ram_trend,
    hdd_trend,
    benchmark_cpu,
    benchmark_ram,
    benchmark_hdd
  } = data;

  // Add state for benchmark values
  const [cpuBenchmark, setCpuBenchmark] = useState(benchmark_cpu);
  const [ramBenchmark, setRamBenchmark] = useState(benchmark_ram);
  const [hddBenchmark, setHddBenchmark] = useState(benchmark_hdd);

  const handleBenchmarkChange = async (type, newBenchmark) => {
    if (!can_operate) return; // Prevent benchmark changes if can_operate is false

    try {
      await setBenchmark(viewId, serverId, type, newBenchmark);

      switch(type) {
        case 'avg_cpu':
          setCpuBenchmark(newBenchmark);
          break;
        case 'avg_ram':
          setRamBenchmark(newBenchmark);
          break;
        case 'avg_hdd':
          setHddBenchmark(newBenchmark);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Failed to set benchmark:', error);
    }
  };

  const UsageBar = ({ value, max, label, initialBenchmark = 99, type, onBenchmarkChange, extraLabel = null }) => {
    const [benchmark, setBenchmark] = useState(initialBenchmark);
    const [isDragging, setIsDragging] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const barRef = useRef(null);
  
    const fillWidth = (value / max) * 100;
    const benchmarkPosition = benchmark ? (benchmark / max) * 100 : null;
  
    const updateBenchmark = (clientX) => {
      if (barRef.current && can_operate) {
        const rect = barRef.current.getBoundingClientRect();
        const position = clientX - rect.left;
        const newBenchmark = Math.round((position / rect.width) * max);
        setBenchmark(Math.max(0, Math.min(newBenchmark, max)));
      }
    };
  
    const handleStart = (e) => {
      if (!can_operate) return;
      setIsDragging(true);
      updateBenchmark(e.clientX || e.touches[0].clientX);
    };
  
    const handleMove = (e) => {
      if (isDragging && can_operate) {
        updateBenchmark(e.clientX || e.touches[0].clientX);
      }
    };
  
    const handleEnd = () => {
      if (!can_operate) return;
      setIsDragging(false);
      if (onBenchmarkChange) {
        onBenchmarkChange(type, benchmark);
      }
    };
  
    useEffect(() => {
      const handleMouseMove = (e) => handleMove(e);
      const handleTouchMove = (e) => {
        e.preventDefault();
        handleMove(e);
      };
  
      if (isDragging && can_operate) {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);
      }
  
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('mouseup', handleEnd);
        document.removeEventListener('touchend', handleEnd);
      };
    }, [isDragging, benchmark]);
  
    const barColor = "#4784e6";
  
    return (
      <div className="mb-2">
        <div className="flex justify-between text-sm mb-1">
          <span>{label}:</span>
          <span>
            {value.toFixed(2)}% {extraLabel ? `[${extraLabel}]` : ''}
          </span>
        </div>
        <div 
          ref={barRef}
          className={`relative h-2 bg-gray-600 rounded-full ${can_operate ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          onMouseDown={handleStart}
          onTouchStart={handleStart}
        >
          <div
            className={`h-full rounded-full`}
            style={{ width: `${fillWidth}%`, backgroundColor: barColor}}
          ></div>
          {benchmark !== null && (
            <div 
              className={`absolute top-0 h-full w-1 bg-red-500 ${can_operate ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              style={{ 
                left: `${benchmarkPosition}%`,
                height: '200%',
                top: '-50%',
                boxShadow: '0 0 10px rgba(239, 68, 68, 0.7)'
              }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onTouchStart={() => setShowTooltip(true)}
              onTouchEnd={() => setShowTooltip(false)}
            >
              {showTooltip && (
                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-1 px-2 py-1 bg-gray-800 text-white text-xs rounded shadow whitespace-nowrap select-none">
                  Benchmark: {benchmark}%
                </div>
              )}
            </div>
          )}
          {value > benchmark && (
            <div className="absolute -right-1 top-1/2 -translate-y-1/2 ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500 animate-pulse" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              <div className="absolute -inset-1 bg-red-500 opacity-30 rounded-full blur-sm animate-pulse"></div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const DualUsageBar = ({ avgUsage, peakUsage, label, max = 100, initialBenchmark = 99, onBenchmarkChange, type, avgColor = '#3B82F6', peakColor = '#93C5FD' }) => {
    const [benchmark, setBenchmark] = useState(initialBenchmark);
    const [isDragging, setIsDragging] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const barRef = useRef(null);
  
    const updateBenchmark = (clientX) => {
      if (barRef.current && can_operate) {
        const rect = barRef.current.getBoundingClientRect();
        const position = clientX - rect.left;
        const newBenchmark = Math.round((position / rect.width) * max);
        setBenchmark(Math.max(0, Math.min(newBenchmark, max)));
      }
    };
  
    const handleStart = (e) => {
      if (!can_operate) return;
      e.preventDefault(); // Prevent default behavior
      setIsDragging(true);
      updateBenchmark(e.type.includes('mouse') ? e.clientX : e.touches[0].clientX);
    };
  
    const handleMove = (e) => {
      if (isDragging && can_operate) {
        e.preventDefault(); // Prevent default behavior
        updateBenchmark(e.type.includes('mouse') ? e.clientX : e.touches[0].clientX);
      }
    };
  
    const handleEnd = () => {
      if (!can_operate) return;
      setIsDragging(false);
      if (onBenchmarkChange) {
        onBenchmarkChange(type, benchmark);
      }
    };
  
    useEffect(() => {
      const handleMouseMove = (e) => handleMove(e);
      const handleTouchMove = (e) => handleMove(e);
  
      if (isDragging && can_operate) {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleTouchMove, { passive: false });
        window.addEventListener('mouseup', handleEnd);
        window.addEventListener('touchend', handleEnd);
      }
  
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('mouseup', handleEnd);
        window.removeEventListener('touchend', handleEnd);
      };
    }, [isDragging, benchmark]);
  
    const benchmarkPosition = (benchmark / max) * 100;
  
    return (
      <div className="mb-4">
        <div className="flex justify-between text-sm mb-0 select-none">
          <span>{label}:</span>
        </div>
        <div className="flex justify-between text-sm mb-1 select-none">
          <span>
            Avg: {avgUsage.toFixed(2)}% | Peak: {peakUsage.toFixed(2)}%
          </span>
        </div>
        <div 
          ref={barRef}
          className={`relative w-full h-2 bg-gray-700 rounded-full ${can_operate ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          onMouseDown={handleStart}
          onTouchStart={handleStart}
        >
          <div 
            className="absolute top-0 left-0 h-full"
            style={{ width: `${peakUsage}%`, backgroundColor: peakColor }}
          >
            <div 
              className="absolute top-0 left-0 h-full"
              style={{ width: `${(avgUsage / peakUsage) * 100}%`, backgroundColor: avgColor }}
            ></div>
          </div>
          <div 
            className={`absolute top-0 h-full w-1 bg-red-500 ${can_operate ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            style={{ 
              left: `${benchmarkPosition}%`,
              height: '200%',
              top: '-50%',
              boxShadow: '0 0 10px rgba(239, 68, 68, 0.7)'
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onTouchStart={() => setShowTooltip(true)}
            onTouchEnd={() => setShowTooltip(false)}
          >
            {showTooltip && (
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-1 px-2 py-1 bg-gray-800 text-white text-xs rounded shadow whitespace-nowrap select-none">
                Benchmark: {benchmark}%
              </div>
            )}
          </div>
          {avgUsage > benchmark && (
            <div className="absolute -right-1 top-1/2 -translate-y-1/2 ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500 animate-pulse" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              <div className="absolute -inset-1 bg-red-500 opacity-30 rounded-full blur-sm animate-pulse"></div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label, enabledLines }) => {
    //console.log("enabledLines", enabledLines);
    //console.log("payload", payload);
    //console.log("enabledLines", Object.entries(enabledLines));
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-gray-800 border border-gray-700 p-2 rounded shadow-lg">
          <p className="label text-gray-400 text-xs">{`${payload[0].payload.type}: ${label}`}</p>
          {payload[0].payload.max && (
                <p className="value text-gray-200 font-semibold">{`Max: ${payload[0].payload.max}%`}</p>
          )}
          <p className="value text-gray-200 font-semibold">{`Average: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipSmallChart = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-gray-800 border border-gray-700 p-2 rounded shadow-lg">
          <p className="label text-gray-400 text-xs">{`Time: ${new Date(payload[0].payload.date).toLocaleTimeString()}`}</p>
          <p className="value text-gray-200 font-semibold">{`Usage: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  const FullTrendChart = ({ title, serverId, setPaused }) => {
    const [selectedDuration, setSelectedDuration] = useState('Hour');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [enabledLines, setEnabledLines] = useState({});
    const [data, setData] = useState([]);
    const [apps, setApps] = useState([]);
    const [otherResources, setOtherResources] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    //setPaused(true); // Pause the data fetching in the background

    const colors = [
      '#00FFFF', '#FF00FF', '#FFFF00', '#FF3300', '#33FF00',
      '#0066FF', '#FF6600', '#9900FF', '#00FF99', '#FF0099'
    ];
    const resource_colors = ['#00ffff', '#ff00ff']; // Cyan and Magenta for a techy look

    useEffect(() => {
      const loadData = async () => {
        setIsLoading(true);
        try {
          const { data: newData, apps: newApps, otherResources: newOtherResources } = await fetchChartData(serverId, title.split(' ')[0].toLowerCase(), selectedDuration, currentDate);
          setData(newData);
          setApps(newApps);
          setOtherResources(newOtherResources);
          setEnabledLines(newApps.reduce((acc, [app, _]) => ({ ...acc, [app]: false }), {}));
        } catch (error) {
          console.error("Failed to fetch chart data:", error);
        } finally {
          setIsLoading(false);
        }
      };

      loadData();
    }, [selectedDuration, currentDate]);

    const handleDurationChange = (duration) => {
      const now = new Date();
      let newDate = new Date(currentDate);
      
      switch (duration) {
        case 'Hour':
          if (selectedDuration === 'Year') {
            newDate = new Date(newDate.getFullYear(), 11, 31, 23, 0, 0, 0);
          } else if (selectedDuration === 'Month') {
            newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0, 23, 0, 0, 0);
          } else if (selectedDuration === 'Day') {
            newDate.setHours(23, 0, 0, 0);
          }
          if (newDate > now) {
            newDate = new Date(now);
            newDate.setHours(newDate.getHours(), 0, 0, 0);
          }
          break;
        case 'Day':
          if (selectedDuration === 'Year') {
            newDate = new Date(newDate.getFullYear(), 11, 31);
          } else if (selectedDuration === 'Month') {
            newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
          }
          newDate.setHours(0, 0, 0, 0);
          if (newDate > now) {
            newDate = new Date(now);
            newDate.setHours(0, 0, 0, 0);
          }
          break;
        case 'Month':
          if (selectedDuration === 'Year') {
            newDate = new Date(newDate.getFullYear(), 11, 1);
          }
          newDate.setDate(1);
          newDate.setHours(0, 0, 0, 0);
          if (newDate > now) {
            newDate = new Date(now);
            newDate.setDate(1);
            newDate.setHours(0, 0, 0, 0);
          }
          break;
        case 'Year':
          newDate.setMonth(0, 1);
          newDate.setHours(0, 0, 0, 0);
          if (newDate.getFullYear() > now.getFullYear()) {
            newDate.setFullYear(now.getFullYear());
          }
          break;
      }
      setSelectedDuration(duration);
      setCurrentDate(newDate);
    };

    const handleNavigation = (direction) => {
      const newDate = new Date(currentDate);
      const now = new Date();
      switch (selectedDuration) {
        case 'Hour': 
          newDate.setHours(newDate.getHours() + direction);
          if (newDate > now) {
            newDate.setTime(now.getTime() + 3600000);
            newDate.setMinutes(0, 0, 0);
          }
          break;
        case 'Day': 
          newDate.setDate(newDate.getDate() + direction);
          if (newDate > now) {
            newDate.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
            newDate.setHours(0, 0, 0, 0);
          }
          break;
        case 'Month': 
          newDate.setMonth(newDate.getMonth() + direction);
          if (newDate > now) {
            newDate.setFullYear(now.getFullYear(), now.getMonth(), 1);
            newDate.setHours(0, 0, 0, 0);
          }
          break;
        case 'Year': 
          newDate.setFullYear(newDate.getFullYear() + direction);
          if (newDate > now) newDate.setFullYear(now.getFullYear());
          break;
      }
      setCurrentDate(newDate);
    };

    const getSelectedRange = () => {
      const endDate = new Date(currentDate);
      const startDate = new Date(currentDate);
      switch (selectedDuration) {
        case 'Hour':
          startDate.setHours(startDate.getHours());
          endDate.setHours(endDate.getHours()+1);
          startDate.setMinutes(0, 0);
          endDate.setMinutes(0, 0);
          return `${startDate.toLocaleTimeString()} - ${endDate.toLocaleTimeString()} ${startDate.toLocaleDateString()}`;
        case 'Day':
          return endDate.toLocaleDateString();
        case 'Month':
          return `${endDate.toLocaleString('default', { month: 'long' })} ${endDate.getFullYear()}`;
        case 'Year':
          return endDate.getFullYear().toString();
      }
    };

    const handleChartClick = (data) => {
      if (data && data.activePayload && data.activePayload.length) {
        const clickedDate = new Date(data.activePayload[0].payload.fullDate);
        if (clickedDate instanceof Date && !isNaN(clickedDate)) {
          let newDuration;
          switch (selectedDuration) {
            case 'Year':
              newDuration = 'Month';
              setCurrentDate(new Date(clickedDate.getFullYear(), clickedDate.getMonth(), 1));
              break;
            case 'Month':
              newDuration = 'Day';
              setCurrentDate(new Date(clickedDate.getFullYear(), clickedDate.getMonth(), clickedDate.getDate()));
              break;
            case 'Day':
              newDuration = 'Hour';
              setCurrentDate(new Date(clickedDate.getFullYear(), clickedDate.getMonth(), clickedDate.getDate(), clickedDate.getHours()));
              break;
            default:
              return;
          }
          setSelectedDuration(newDuration);
        }
      }
    };

    const toggleLine = (line) => {
      setEnabledLines(prev => ({ ...prev, [line]: !prev[line] }));
    };

    if (isLoading) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      );
    }


    return (
      <>
        <div className="flex justify-between items-center mb-2">
         <div className="hidden md:flex md:justify-center space-x-2 mr-8">
            {otherResources.sort((a, b) => b[1] - a[1]).slice(0, 2).map(([line, contribution], index) => {
              return (
                <button
                  key={line}
                  onClick={() => toggleLine(line)}
                  className={`relative px-1 py-1 text-sm font-semibold rounded-full transition-all duration-300 ease-in-out overflow-hidden ${
                    enabledLines[line] 
                      ? `bg-gradient-to-r from-${resource_colors[index]}/80 to-${resource_colors[index]}/60 text-white transform scale-105` 
                      : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
                  }`}
                  style={{
                    width: '60px', 
                    border: enabledLines[line] ? `2px solid ${resource_colors[index]}` : '2px solid transparent'
                  }}
                >
                  <div className="relative z-10 flex items-center justify-center w-full">
                    <span className="truncate">{line.charAt(0).toUpperCase() + line.slice(1)}</span>
                  </div>
                  <div 
                    className="absolute top-0 left-0 h-full transition-all duration-300 ease-in-out" 
                    style={{
                      width: `${contribution}%`,
                      background: enabledLines[line] ? `linear-gradient(90deg, ${resource_colors[index]}66, ${resource_colors[index]}00)` : 'rgba(75, 85, 99, 0.5)'
                    }}
                  ></div>
                </button>
              );
            })}
          </div>
          <div className="hidden md:flex md:justify-end flex-nowrap gap-2 p-1 bg-gray-800 rounded-lg mr-8 overflow-x-auto" style={{ scrollbarWidth: 'none' }}>
            <div className="flex flex-nowrap gap-2 overflow-x-auto" style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
              {apps.sort((a, b) => b[1] - a[1]).map(([line, contribution], index) => (
                <button
                  key={line}
                  onClick={() => toggleLine(line)}
                  className={`relative px-2 py-1 text-xs sm:text-sm font-semibold rounded-full transition-all duration-300 ease-in-out overflow-hidden flex-shrink-0 ${
                    enabledLines[line] 
                      ? `bg-gradient-to-r from-${colors[index % colors.length]}/80 to-${colors[index % colors.length]}/60 text-white transform scale-105` 
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                  style={{
                    minWidth: '80px', 
                    maxWidth: '150px',
                    border: enabledLines[line] ? `1px solid ${colors[index % colors.length]}` : '2px solid transparent'
                  }}
                >
                  <div className="relative z-10 flex items-center justify-center w-full">
                    <span className="truncate">{line.charAt(0).toUpperCase() + line.slice(1)}</span>
                  </div>
                  <div 
                    className="absolute top-0 left-0 h-full transition-all duration-300 ease-in-out" 
                    style={{
                      width: `${contribution}%`,
                      background: enabledLines[line] ? `linear-gradient(90deg, ${colors[index % colors.length]}66, ${colors[index % colors.length]}00)` : 'rgba(75, 85, 99, 0.5)'
                    }}
                  ></div>
                </button>
              ))}
            </div>
          </div>
          <div className="flex space-x-1">
            <button onClick={() => handleNavigation(-1)} className="px-2 py-1 bg-gray-700 text-gray-300 rounded hover:bg-gray-600">&lt;</button>
            {['Hour', 'Day', 'Month', 'Year'].map((duration) => (
              <button
                key={duration}
                onClick={() => handleDurationChange(duration)}
                className={`px-3 py-1 rounded ${selectedDuration === duration ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
              >
                <span className="hidden lg:inline">{duration}</span>
                <span className="lg:hidden">{duration.charAt(0)}</span>
              </button>
            ))}
            <button onClick={() => handleNavigation(1)} className="px-2 py-1 bg-gray-700 text-gray-300 rounded hover:bg-gray-600">&gt;</button>
            <button onClick={() => {
              const now = new Date();
              now.setMinutes(0, 0, 0);
              setCurrentDate(now);
            }} className="px-3 py-1 bg-gray-700 text-gray-300 rounded hover:bg-gray-600">
                <span className="lg:hidden"><TimerReset className="w-4 h-4" /></span>
                <span className="hidden lg:inline">Now</span>
              </button>
          </div>
        </div>
        <div className="h-[calc(100%-7rem)] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart 
              data={data} 
              margin={{ top: 5, right: 30, bottom: 30, left: 5 }}
              onClick={handleChartClick}
            >
              <Area 
                type="monotone" 
                dataKey="value" 
                stroke="none"
                fill={`url(#${title.toLowerCase().replace(/\s+/g, '-')}-gradient)`}
                fillOpacity={1}
              />
              <Area 
                  type="monotone" 
                  dataKey="max" 
                  stroke="none"
                  strokeWidth={1} 
                  strokeDasharray="3 3"
                  fillOpacity={0.1}
                  fill='white'
                  dot={false} 
              />
              {otherResources.map(([resource, _], index) => (
                enabledLines[resource] && (
                  <Area 
                    key={resource}
                    type="monotone" 
                    dataKey={data => data['other_resources'][index]} 
                    stroke={resource_colors[index % colors.length]}
                    strokeWidth={2} 
                    fillOpacity={0}
                    dot={false} 
                  />
                )
              ))}
              {apps.map(([app, _], index) => (
                enabledLines[app] && (
                  <Area 
                    key={app}
                    type="monotone" 
                    dataKey={data => data['apps'][index]} 
                    stroke={colors[index % colors.length]}
                    strokeWidth={0.5} 
                    fillOpacity={0}
                    dot={false} 
                  />
                )
              ))}
              <XAxis 
                dataKey="date" 
                axisLine={true}
                tickLine={true}
                tick={{ fontSize: 12, fill: '#7f8182' }}
                tickFormatter={(value, index) => {
                  if (selectedDuration === 'Hour') {
                    return value;
                  }
                  return value;
                }}
              />
              <YAxis 
                hide={false}
                domain={[0, 'dataMax']}
                tick={true}
                axisLine={true}
                tickLine={true}
              />
              <Tooltip content={<CustomTooltip enabledLines={enabledLines} />}/>
              
            </AreaChart>
            <div className="text-center text-gray-400">
              Selected Range: {getSelectedRange()}
            </div>
          </ResponsiveContainer>
        </div>
      </>
    );
  };

  const TrendChart = ({ data, colors, title, icon: Icon, benchmark, name }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
      handlePause(true);
    };
    const closeModal = () => {
      setIsModalOpen(false);
      handlePause(false);
    };

    const handleModalClick = (e) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    };

    return (
      <div className="trend-chart cursor-pointer" onClick={openModal}> 
        <div 
          className="flex items-center mb-2 hover:bg-gray-700 rounded p-1 transition-colors"
        >
          <h3 className="flex items-center font-semibold text-gray-400 select-none">
            {Icon && <Icon className="mr-2" size={18} />}
            {title}
          </h3>
        </div>
        <ResponsiveContainer width="100%" height={100}>
          <AreaChart data={data} margin={{ top: 5, right: 30, bottom: 5, left: 5 }}>
            <defs>
              <linearGradient id={`${title.toLowerCase().replace(/\s+/g, '-')}-gradient`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={colors[0]} stopOpacity={0.9} />
                <stop offset="50%" stopColor={colors[1]} stopOpacity={0.7} />
                <stop offset="100%" stopColor={colors[2]} stopOpacity={0.5} />
              </linearGradient>
            </defs>
            <Area 
              type="monotone" 
              dataKey="value" 
              stroke="none"
              fill={`url(#${title.toLowerCase().replace(/\s+/g, '-')}-gradient)`}
              fillOpacity={1}
            />
            <XAxis 
              dataKey="date" 
              axisLine={false} 
              tickLine={false} 
              tick={false} 
              label={{ 
                  value: 'Last 60 minutes', 
                  position: 'insideBottom', 
                  offset: 16,
                  style: { fontSize: 12, fill: '#7f8182', textAnchor: 'start' }
                }}
            />
            <YAxis 
              hide={false}
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `${value}%`}
              tick={{ fontSize: 12 }}
            />
            <Tooltip content={<CustomTooltipSmallChart />}/>
            {benchmark !== null && (
              <ReferenceLine y={benchmark} stroke="red" strokeDasharray="3 3" />
            )}
          </AreaChart>
        </ResponsiveContainer>
        {isModalOpen && createPortal(
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleModalClick}>
            <div className='bg-blue-900 rounded-lg w-[95%] h-4/5' onClick={(e) => e.stopPropagation()}>
              <div className="bg-gray-900 p-4 m-0 rounded-lg w-full h-full opacity-90">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-bold text-gray-400">
                    {title}
                    <span className="text-sm font-normal text-gray-500 ml-2">
                      - {name}
                    </span>
                  </h2>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      closeModal();
                    }}
                    className="text-gray-400 hover:text-gray-200"
                  >
                    &times;
                  </button>
                </div>
                <FullTrendChart 
                  serverId={serverId}
                  title={title}
                />
              </div>
            </div>
          </div>,
          document.getElementsByTagName("main")[0]
        )}
      </div>
    );
  };


  return (
    <div className="server-overview-card text-gray-300">
      <h2>{name}</h2>
      {is_no_data ? (
        <div className="flex flex-col items-center justify-center h-full">
          <TimerReset className="w-16 h-16 text-gray-500 mb-4" />
          <p className="text-lg text-gray-500">No data available</p>
          <button
            onClick={() => handleExteralButtonClick("servers")}
            className="bg-blue-900 rounded-lg shadow-lg mt-4 w-full hover:bg-blue-800 transition-colors duration-200"
          >
            <p className="text-blue-200 text-center p-4">
              No data available. Click here to check server configurations.
            </p>
          </button>
        </div>
      ) : (
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
          <div className="relative pr-4 border-r border-gray-700">
            <div className="absolute top-1 right-2 text-xs text-gray-400">- Last 24 hours</div>
            <DualUsageBar 
              avgUsage={avg_cpu} 
              peakUsage={max_cpu} 
              label="CPU Usage" 
              onBenchmarkChange={handleBenchmarkChange}
              type="avg_cpu"
              avgColor='#3333FF'
              initialBenchmark={cpuBenchmark}
            />
            <DualUsageBar 
              avgUsage={avg_ram} 
              peakUsage={max_ram} 
              label="RAM Usage"  
              onBenchmarkChange={handleBenchmarkChange}
              type="avg_ram"
              avgColor='#3333FF'
              peakColor='#93C5FD'
              initialBenchmark={ramBenchmark}
            />
            <DualUsageBar 
              avgUsage={avg_hdd} 
              peakUsage={max_hdd} 
              label="Disk I/O Usage"  
              onBenchmarkChange={handleBenchmarkChange}
              type="avg_hdd"
              avgColor='#3333FF'
              peakColor='#93C5FD'
              initialBenchmark={hddBenchmark}
            />
            
            <div className="text-sm mb-0 select-none"><span>Disk Usage / Performance:</span></div>
            <div className="hdd-details ml-2 text-sm mt-0  select-none">
              {hdd.map((drive, index) => (
                <div key={index} className="hdd-item">
                  <div>
                    <HardDrive size={16} style={{display: 'inline', marginRight: '0.25rem'}} />
                    <span>{drive.drive} </span>
                    <UsageBar value={drive.usage} max={100} initialBenchmark={drive.benchmark} label="Usage" type={`hdd.${index}`} onBenchmarkChange={handleBenchmarkChange} extraLabel={`${drive.used.toFixed(0)}/${drive.total.toFixed(0)}GB`}/>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="trend-chart">
              <TrendChart 
                data={cpu_trend} 
                colors={['#F0F0FF', '#6666FF', '#3333FF']}
                title="CPU Usage Trend" 
                icon={Cpu} 
                benchmark={cpuBenchmark}
                name={name}
              />
            </div>
            <div className="trend-chart">
              <TrendChart 
                data={ram_trend} 
                colors={['#F0F0FF', '#6666FF', '#3333FF']}
                title="RAM Usage Trend" 
                icon={MemoryStick} 
                benchmark={ramBenchmark}
                name={name}
              />
            </div>
            <div className="trend-chart">
              <TrendChart 
                data={hdd_trend} 
                colors={['#F0F0FF', '#6666FF', '#3333FF']}
                title="Disk I/O Usage Trend" 
                icon={HardDrive} 
                benchmark={hddBenchmark}
                name={name}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServerOverview;